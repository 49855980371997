import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import ImageMeta from './ImageMeta'
import { getMetaImageUrls } from '.'

const ArticleMetaMD = ({ data, canonical }) => {
    const post = data.markdownRemark
    const fm = post.frontmatter
    const { siteMetadata } = data.site

    // Convert the frontmatter date into ISO String but, and use a fixed
    // date, if no date is set. The published date should not change once set.
    const publishedAtISODate = fm.date ? new Date(fm.date).toISOString() : new Date(`2018-10-15`).toISOString()
    const seoImage = getMetaImageUrls()

    return (
        <>
            <Helmet>
                <title>{fm.meta_title || fm.title}</title>
                <meta name="description" content={fm.meta_description || post.excerpt} />
                <link rel="canonical" href={canonical} />

                <meta property="og:site_name" content={siteMetadata.title} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={fm.meta_title || fm.title} />
                <meta property="og:description" content={fm.meta_description || post.excerpt} />
                <meta property="og:url" content={canonical} />
                <meta property="article:published_time" content={publishedAtISODate} />
                {fm.keywords && fm.keywords.length ? fm.keywords.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />)) : null}
            </Helmet>
            <ImageMeta image={seoImage} />
        </>
    )
}

ArticleMetaMD.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                keywords: PropTypes.arrayOf(
                    PropTypes.string,
                ),
                meta_title: PropTypes.string,
                meta_description: PropTypes.string,
                date: PropTypes.string,
            }).isRequired,
            excerpt: PropTypes.string.isRequired,
            timeToRead: PropTypes.number,
        }).isRequired,
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                siteUrl: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
}

export default ArticleMetaMD
