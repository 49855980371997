import React, { useEffect } from 'react'
import { Spirit } from '../../../styles/spirit-styles'
import { Logo } from '../.'

const listItemClass = `mb2 lh-1-65` // TODO: Probably should go to spirit-styles.js
const linkClass = `link pa2 midgrey hover-blue dib mr5 f8`

function Footer () {

  useEffect(() => {
    const script = document.createElement('script')
    script.innerHTML =  `(function(y,o,u,k,e){y['YoukeInstance'] = k;y[k]=y[k]||function(){(y[k].e = y[k].e || []).push(arguments)};e=o.createElement('script');e.async=1;e.src=u;o.currentScript.parentNode.insertBefore(e,o.currentScript);})(window,document,'https://static.cdn.youke.co/js/widget.js','$youke');$youke('load','duohui');`
    document.body.appendChild(script)
  }, [])

  return (
    <footer className="bt b--whitegrey">

      <section className={`${Spirit.page.xl} m1`}>

        <div className="flex justify-between items-center pt4">
          <ul className="flex list pa0 ma0 items-center">
            <li className={listItemClass}><a href="https://www.duohui.cn/" target="_blank" rel="noopener noreferrer" className="dib pt2 mr6"><Logo /></a></li>
            <li className={`${listItemClass} dn db-l`}><a href="https://docs.duohui.cn/" className={linkClass}>多会文档</a></li>
          </ul>

          <ul className="flex list pa0 ma0 items-center">
            <li className={listItemClass}><a href="https://www.duohui.cn/" target="_blank" rel="noopener noreferrer" className="link pa2 midgrey hover-blue dib mr0">多会</a></li>
            &emsp;
            <li className={`${listItemClass} dn db-l`} style={{ color: '#bbb'}}>沪ICP备17014612号-2</li>
          </ul>
        </div>

      </section>
    </footer>
  )
}

export default Footer
