import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { Icon, Box } from '../common'
import { Spirit } from '../../styles/spirit-styles'

const IndexShowcase = ({data}) => {
  // console.log('---', data)
  const edges = data.allMarkdownRemark.edges;

  return (
    <section className={`${Spirit.page.xl} col-12 mt8 mt-vw3-ns bt bn-ns b--whitegrey pt5 pt0-ns`}>
      <a href="/showcase/" className={`${Spirit.h3} link darkgrey hover-midgrey`}>活动案例</a>
      <div className="grid-integrations-index mt4 mt6-l f8">
        {
          edges.map( (item, index ) => {
            return (
              <Box key={index} to={item.node.fields.slug} className="flex flex-column justify-between items-center middarkgrey pa1 pt5 pb5 tdn tc" elevation="2" radius="4">
                <div className="flex" style={{height: '160px'}}>
                  <img className="w10 mb1 self-center" src={item.node.frontmatter.avatar} alt="Zapier" />
                </div>
                {item.node.frontmatter.heading}
              </Box>
            )
          })
        }
        <Box to="/showcase/" className="flex flex-column justify-between items-center middarkgrey pa2 pt5 pb5 tdn" elevation="2" radius="4">
          <Icon name="more" className="w8 nudge-top--6" />
          更多
        </Box>
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
          allMarkdownRemark(
              limit: 7,
              sort: { fields: [frontmatter___date], order: DESC },
              filter: { frontmatter: { category: { eq: "showcase"} } },
          ) {
              edges {
                  node {
                      fields {
                          slug
                      }
                      frontmatter {
                          title
                          heading
                          avatar
                      }
                  }
              }
          }
      }
    `}
    render={data => <IndexShowcase data={data} {...props} />}
  />
)